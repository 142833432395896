import React from "react"
import { graphql, Link } from "gatsby"

import { GitHub, Twitter, Linkedin } from "react-feather"
import IndexLayout from "../layouts/indexLayout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { github, twitter, linkedin } = data.site.siteMetadata
  return (
    <IndexLayout>
      <SEO title="Home" />
      <div>
        <div className="u-mb">
          <h4 className="u-mb-">Services</h4>
          <p>
            <a
              href="https://michaelsheedy.com"
              target="_blank"
              rel="noreferrer"
            >
              michaelsheedy.com
            </a>
            {` `}— Analytics Consulting
          </p>
        </div>

        <div className="u-mb">
          <h4 className="u-mb-">Products</h4>
          <p className="u-mb-">
            <a href="https://boltcart.shop" target="_blank" rel="noreferrer">
              Bolt Cart
            </a>
            {` `}— Lightning-fast shopping cart powered by Google Sheets and
            Stripe
          </p>
        </div>

        <div className="u-mb">
          <h4 className="u-mb-">Resources</h4>
          <p className="u-mb-">
            <a
              href="https://michaelsheedy.com/privacy-experience-resources"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Experience Resources
            </a>
            {` `}— People-centred resources for product teams that want to
            design better privacy experiences.
          </p>
          <p className="u-mb-">
            <a
              href="https://theprivacycheckup.com"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Checkup
            </a>
            {` `}— The digital privacy & security assessment tool for Apple
            users.
          </p>
          <p className="u-mb-">
            <a
              href="https://privacyselfdefense.com"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Self Defense
            </a>
            {` `}— Privacy tips & guides for Apple users.
          </p>
        </div>

        <div className="u-mb">
          <h4 className="u-mb-">Writing</h4>

          <p className="u-mb-">
            <a
              href="https://michaelsheedy.com"
              target="_blank"
              rel="noreferrer"
            >
              Privacy & Analytics
            </a>
          </p>

          <p className="u-mb-">
            <Link to="/articles">General musings</Link>
          </p>
        </div>

        <div className="u-mb">
          <hr />
          <div style={{ display: `flex`, justifyContent: `center` }}>
            <a
              href={`https://github.com/${github}`}
              target="_blank"
              rel="noreferrer"
              style={{ margin: `0 24px` }}
            >
              <GitHub />
            </a>
            <a
              href={`https://twitter.com/${twitter}`}
              target="_blank"
              rel="noreferrer"
              style={{ margin: `0 24px` }}
            >
              <Twitter />
            </a>
            <a
              href={`https://www.linkedin.com/in/${linkedin}`}
              target="_blank"
              rel="noreferrer"
              style={{ margin: `0 24px` }}
            >
              <Linkedin />
            </a>
          </div>
        </div>
      </div>
    </IndexLayout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        github
        twitter
        linkedin
      }
    }
  }
`

export default IndexPage
